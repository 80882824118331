@tailwind base;
@tailwind components;
@tailwind utilities;

.tooltip {
  visibility: hidden;
  position: absolute;
}
.has-tooltip:hover .tooltip {
  visibility: visible;
  z-index: 100;
}

* {
  margin: 0;
  padding: 0;
  font-size: 14px;
  max-width: 100%;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  place-items: center;
  height: 100vh;
  font-family: Poppins, sans-serif;
  background-color: #f9f9f9;
  scroll-behavior: smooth;
}

/* Apply max-width to the app for screens wider than 1040px */
@media (min-width: 576px) {
  body {
    max-width: 100%; /* Set your desired maximum width */
    margin: 0 auto; /* Center the content within the maximum width */
  }
}

