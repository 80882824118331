@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");
:root {
  --primary1: #dd946f;
  --primary-hover: #cd7d7c;
  --secondary: #efd0f8;
  --secondary-hover: #b8a1d9;
  --aura: #9265ca80;
  --background1: #261e5a;
  --backgroundLogin: #2c2c2c;
  --background: #3c3353;
  --tabs-bg: #261e35;
  --text-color: white;
  --primary2: #ecb22e;
  --link-color: white;
  --primary: #605DFF;
  --secondary-text: #2E5A8C;
}

.my-form {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1rem;
  background-color: var(--background);
  border: 1px solid var(--primary);
  border-radius: 1rem;
  box-shadow: 0 30px 60px var(--aura);
  min-width: 17.5rem;
  max-width: 28.75rem;
  width: 100%;
  padding: 2rem;
}

.bg-form {
  background-color: var(--backgroundLogin);
  border: 1px solid var(--primary);
}

.wrap-text {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* background: rgba(216, 73, 73, 0.2); */
  width: 100%;
  font-weight: 500;
  font-size: 15px;
}

.pagination-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #222;
  /* padding: 8px 14px; */
  height: 40px;
  width: 40px;
  text-decoration: none;
  transition: background-color 0.2s;
  border: 1px solid #ddd;
  cursor: pointer;
}

.pagination-container button.active {
  background-color: #60a5fa;
  color: white;
  border: 1px solid #7cbddb;
}

.pagination-container button.disabled {
  opacity: 0.2;
}
.pagination-container button:hover:not(.active) {
  background-color: #60a5fa;
}

table {
  width: 100%;
  table-layout: fixed;
  background-color: #f2f2f2;
}

.custom-form-control {
  margin-bottom: 20px;
}

.custom-form-control label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.custom-form-control .custom-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.custom-form-control .custom-input:focus {
  border-color: #007bff; /* Change the border color on focus */
  outline: none; /* Remove the default focus outline */
  box-shadow: 0 0 5px #007bff; /* Add a shadow on focus */
}

.custom-form-control .custom-input::placeholder {
  color: #999; /* Change the placeholder text color */
}

/* Customize other states (e.g., hover, disabled) as needed */
.custom-form-control-focused {
  /* Add custom styles for the focused state here */
  border-color: #007bff; /* Change the border color on focus */
  outline: none; /* Remove the default focus outline */
  box-shadow: 0 0 5px #007bff; /* Add a shadow on focus */
}

p.introA::first-letter {
  color: rgb(30 64 175);
  font-size: 200%;
  font-weight: bold;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--background);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background: var(--text-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background: var(--primary2);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  /* border-radius: 50%; */
}

.slider.round:before {
  border-radius: 50%;
}

.go-to-article-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;
}

.go-to-article-button svg {
  width: 4rem;
  stroke: var(--secondary-text);
}

.go-to-article-button:hover {
  border: 1px solid var(--primary);
}

.box-shadow-custom {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 25px 0px
}
